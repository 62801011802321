<template>
	<div>
		<div class="container">
			<SpRelayers />
		</div>
	</div>
</template>

<script>
export default {
	name: 'Relayers'
}
</script>
