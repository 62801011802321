<template>
	<div>
		<div class="container">
			<!-- this line is used by starport scaffolding # 4 -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'Types'
}
</script>
